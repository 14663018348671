<template>
    <div class="container">
        <div class="row no-gutter">
            <div class="col p-0">
                <div class="claim-form mt-4">
                    <div class="claim-form__header">
                        <h2 class="claim-form__title">Confirm my Credit Card</h2>
                        <div class="claim-form__icon">
                            <img :src="data.imgurl" alt="" />
                        </div>
                    </div>

                    <div class="claim-form__body">
                        <ul class="claim-form__ul" type="none">
                            <li class="claim-form__section section">
                                <h2 class="claim-form__subtitle">Claim</h2>
                                <p>
                                    To enable us to complete your replacement
                                    bike processing. Please provide a credit
                                    card we can use for toll and excess charges.
                                </p>
                            </li>
                            <li class="claim-form__section section">
                                <FormulateInput
                                    type="text"
                                    label="Claim Number"
                                    help="Your claim reference number associated with your replacement bike."
                                    v-model="data.claimNumber" />
                            </li>
                            <li class="claim-form__section section">
                                <FormulateInput
                                    type="text"
                                    label="Leasee Name"
                                    help="Your name"
                                    v-model="data.name" />
                            </li>
                            <li class="claim-form__section section">
                                <FormulateInput
                                    type="text"
                                    label="Bike Rego"
                                    help="Registration of your bike in the claim."
                                    v-model="data.rego" />
                            </li>
                            <li class="claim-form__section section">
                                <h2 class="claim-form__subtitle">Card Details</h2>
                                <p>Enter your credit card details below.</p>
                                <p>Your Right To Ride will process and hold a copy of your card details for the duration of your lease. The card you provide will be used for payments of tolls, fines or excess and will be made upon finalisation of the lease.</p>
                            </li>
                            <li class="claim-form__section section">
                                <FormulateInput
                                    type="checkbox"
                                    label="I authorise Your Right To Ride to process a copy of my card for payment of charges incurred during the lease period. *"
                                    v-model="data.agree" />
                            </li>
                            <li class="claim-form__section claim-form__section--cc section">
                                <div class="claim-form__cc">
                                    <div class="claim-form__cc-title">Credit Card Details</div>
                                    <div class="claim-form__cc-inner">
                                        <FormulateInput
                                            name="name"
                                            class="w-100"
                                            v-model="data.cc.name"
                                            label="Name on card" />

                                        <div data-classification="text" data-type="text" class="formulate-input w-100">
                                            <div class="formulate-input-wrapper">
                                                <label id="formulate--demo-32-pay-my-deposit-28_label" for="formulate--demo-32-pay-my-deposit-28" class="formulate-input-label formulate-input-label--before">Card Number</label>
                                                <stripe-element-card :pk="publishableKey" ref="stripeCard" @token="tokenCreated" />
                                            </div>
                                        </div>

                                        <FormulateInput
                                            class="w-100"
                                            type="submit" />
                                    </div>
                                </div>

                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { StripeElementCard } from '@vue-stripe/vue-stripe';
import Axios from "axios";

export default {
    name: 'pay-deposit-form',
    components: {
        StripeElementCard
    },
    data() {
        return {
            hash: '',
            // to be used as props (value)
            data: {
                showForm: true,
                showSuccess: false,
                showError: false,
                claimNumber: '',
                name: '',
                rego: '',
                agree: false,
                imgurl: 'https://dev.pay.yourrighttoride.com.au/img/logo.png',
                cc: {
                    name: '',
                    number: ''
                }
            },
            publishableKey: 'pk_test_TYooMQauvdEDq54NiTphI7jx'
        }
    },
  watch: {
      'data.agree': function() {
        this.$refs.stripeCard.update();
      },
  },
  created() {
    this.hash = this.$route.params.code;
  },
  mounted() {
      this.loadClaimData();
  },
  methods: {
      tokenCreated(token){
        console.log(token)
      },
      loadClaimData(){
        Axios.get('/efr/credit-card-form/' + this.hash)
          .then(res => {
            if(res && res.data && res.data._status){
              this.data.claimNumber = res.data.data.claimNumber;
              this.data.name = res.data.data.fullName;
              this.data.rego = res.data.data.rego;
            } else {
              this.showForm = false;
              this.showError = true;
            }
          })
          .catch(e => console.warn(e));
      }
    }
}

</script>

<style lang="scss">
.claim-form {
    border: 2px solid rgba(27, 30, 56, 0.1);
    border-color: #00b050;
    border-radius: 8px;
    overflow: hidden;
    max-width: 800px;
    margin-bottom: 2rem;

    &__header {
        display: flex;
        justify-content: space-between;
        align-items: center;

        background-color: #00b050;
        padding: 10px 18px;
    }

    &__footer {
        min-height: 80px;
        background-color: #00b050;
        padding: 10px 18px;
    }

    &__title,
    &__subtitle--light {
        margin: 0;
        color: white;
    }

    &__body {
        padding: 10px;
    }

    &__icon {
        img {
            max-width: 38vw;
        }
    }

    &__ul {
        margin: 0;
        padding: 0;
    }

    &__section {
        margin: 5px 0px 5px 0px;
        padding: 10px;

        h2 {
            padding-bottom: 10px;
            border-bottom: 1px solid rgba(215, 215, 215, 1);
        }

        a {
            color: #00b050;
        }

        &--cc {
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }

    &__cc {
        width: 450px;
    }

    &__cc-inner {
        border-radius: 8px;
        padding: 10px 15px 15px;
        border: 1px solid #d7d7d7;
    }

    &__cc-title {
        font-size: 16px;
        line-height: 22px;
        letter-spacing: normal;
        font-weight: 700;
        margin-bottom: 6px;
    }
    &__sign-wrapper {
        border: 2px solid #d9d9d9;
        border-radius: 13px;
    }
}

div.StripeElement {
    border: 2px solid #d9d9d9 !important;
    min-height: 46px !important;
    box-shadow: none !important;
}

@media screen and (max-width: 600px) {
    .claim-form {
        &__title {
            font-size: 1.7rem;
        }
    }
}

</style>

<style lang="scss" scoped>
.section {
    p {
        color: #847f7f;
        &:last-child {
            margin-bottom: 0;
        }
    }
}


</style>
